import React from 'react';
import {Button, Col, Image, Row} from 'react-bootstrap';
import about_us_image from '../../image/about_us.jpg';
import {useNavigate} from "react-router-dom";
import Pages from "../../enum/pages";
import HomePageSubPages from "../../enum/HomePageSubPages";

const minHeight = 100;
const minHeightInPx = `${minHeight}px`;

const AboutUs = () => {
    const navigate = useNavigate();

    return (
        <>
            <Row className={"py-5"} style={{'minHeight': minHeightInPx}}>
                <div className={"about_us_wrap"}>
                    <Col className={"about_us"}>
                        <Image height={minHeight} src={about_us_image} className={"img-fluid"}/>
                        <div className={"about_us_box p-lg-5 p-3"}>
                            <h1 className={"font-weight-bold"}>Podlaha pre každý interiér</h1>
                            <p>Spájame tradíciu remesla s novodobými trendmi. ALDAM s.r.o. je symbolom spoľahlivosti a kvality v oblasti podlahárstva a stavebných prác. Nechajte sa inšpirovať našou prácou a transformujte svoj priestor s partnerom, ktorému môžete dôverovať.</p>
                            <p>Chcete pomoc s výberom?</p>
                            <Button variant="light" onClick={() => {
                                navigate(`/${Pages.Home}${HomePageSubPages.ContactInfo}`);
                            }}>KONTAKTUJTE NÁS</Button>
                        </div>
                    </Col>
                </div>
            </Row>
        </>
    );
}

export default AboutUs;
